const React = require("react");
const { Helmet } = require("react-helmet");

exports.wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/cep5qnb.css" />
      </Helmet>
      {element}
    </>
  )
}